import Vue from 'vue';

export default {
    /* find(parametros) {
        return Vue.axios.get("periodo-incidencias/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("periodo-incidencias/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }, */
    update(parametros) {
        return Vue.axios.put("periodo-incidencias", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateEstatus(parametros) {
        return Vue.axios.put("periodo-incidencias/estatus", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateImporte(parametros) {
        return Vue.axios.put("incidencias_nomina/modificar-importe", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateComplemento(parametros) {
        return Vue.axios.put("incidencias_nomina/recalcular-complemento", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("periodo-incidencias/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("periodo-incidencias", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getUsuarios(parametros) {
        return Vue.axios.post("periodo-incidencias/usuarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDepartamentos(parametros) {
        return Vue.axios.get("tipo_nominas/departamentos", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },


}