import gql from 'graphql-tag';

const periodos_incidenciasTableQuery = gql`
    query periodos_incidencias($whereConditions:PeriodosIncidenciasWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        periodos_incidencias(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                tipo_nomina_id,
                periodo_id,
                usuario_captura_id,
                usuario_autoriza_id,
                periodo,
                inicio_captura,
                final_captura,
                estatus,
                activo,
                comentarios,
                empresa{
                    id,
                    nombre
                }
                departamentos{
                    id
                },
                tipoNomina{
                    clave,
                    descripcion
                }
                periodoModel{
                    numero,
                    fecha_inicial,
                    fecha_final,
                    cerrado
                }
                cliente{
                    nombre
                }
                historicos{
                    id,
                    motivo,
                    estatus,
                    responsable{
                        nombre,
                        apellido_paterno,
                        apellido_materno
                    }
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const getPeriodoIncidenciaQuery = gql`
    query  periodo_incidencias($id:ID) {
                periodo_incidencias(id:$id){
                    id,
                    cliente_id,
                    empresa_id,
                    tipo_nomina_id,
                    periodo_id,
                    usuario_captura_id,
                    usuario_autoriza_id,
                    periodo,
                    inicio_captura,
                    final_captura,
                    estatus,
                    activo,
                    comentarios,
                    empresa{
                        id,
                        nombre
                    }
                    departamentos{
                        id
                    },
                    tipoNomina{
                        clave,
                        descripcion
                    }
                    periodoModel{
                        numero,
                        fecha_inicial,
                        fecha_final
                    }
                    cliente{
                        nombre,
                        odesa
                    }
                }
    }
`

const queries = { periodos_incidenciasTableQuery, getPeriodoIncidenciaQuery };

export default queries;
