<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <!-- !temporal?itemsLinks:itemsLinksTemporal -->
                    <v-breadcrumbs :items="itemsLinksComputed">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <template v-if="!temporal"> 
                    <v-col cols="12" lg="12" class="pa-0 ma-0" >
                        <v-row class="mb-2">
                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <div class="pt-2" style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                    <hr class="vr" />
                                    <p class="tblName">Información del período de complementos</p>
                                </div>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                                <div class="float-right pa-0 mb-0 " >    
                                    <div class="float-left" style="padding-right: 10px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    :disabled="!opcionAgregarComplemento"
                                                    fab
                                                    color="#1E2245"
                                                    right
                                                    class="elevation-0"
                                                    @click="openModal('add')"
                                                    width="38"
                                                    height="38"
                                                >
                                                    <v-icon color="#ffffff" size="20">add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Agregar</span>
                                        </v-tooltip>
                                    </div> 



                                    <v-menu buttom rounded offset-y content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                rounded
                                                color="#58C5D8"
                                                dark
                                                class="btn-agrupar-por justify-space-between elevation-0 mr-2 pr-2"
                                            >
                                                Acciones
                                                <v-icon>keyboard_arrow_down</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="opcionEnviarRevision
                                                        ?accionesIncidencias('REVISION')
                                                        :'#'"
                                                :class="opcionEnviarRevision
                                                        ?''
                                                        :'cursor-not-allowed'"
                                            >   
                                                <v-list-item-title>Enviar a revisión</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="opcionEnviarRecaptura
                                                        ?accionesIncidencias('RECAPTURA')
                                                        :'#'"
                                                :class="opcionEnviarRecaptura
                                                        ?''
                                                        :'cursor-not-allowed'"
                                            >   
                                                <v-list-item-title>Enviar a recaptura</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="opcionAutorizarPeriodo
                                                        ?accionesIncidencias('AUTORIZADO')
                                                        :'#'"
                                                :class="opcionAutorizarPeriodo
                                                        ?''
                                                        :'cursor-not-allowed'"
                                            >   
                                                <v-list-item-title>Autorizar periodo</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="opcionRechazarPeriodo
                                                        ?accionesIncidencias('RECHAZADO')
                                                        :'#'"
                                                :class="opcionRechazarPeriodo
                                                        ?''
                                                        :'cursor-not-allowed'"
                                            >   
                                                <v-list-item-title>Rechazar periodo</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>                                    
                                </div>
                            </v-col>
                        </v-row>   
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                        <v-card elevation:0 class="elevation-0 borde-card d-flex">
                            <v-col xs="12" sm="3" md="3" class="pb-0 pt-8 pl-8">
                                <v-text-field
                                    disabled
                                    outlined
                                    label="Período de complementos"
                                    v-model="descripcion_periodo"
                                    class="pa-0 ma-0"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="3" md="3" class="pb-0 pt-8">
                                <v-text-field
                                    disabled
                                    outlined
                                    v-model="inicioCaptura"
                                    label="Inicio de captura"
                                    class="ma-0 pa-0"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="3" md="3" class="pb-0 pt-8">
                                <v-text-field
                                    disabled
                                    outlined
                                    v-model="finCaptura"
                                    label="Final de captura"
                                    class="ma-0 pa-0"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col xs="12" sm="3" md="3" class="pb-0 pt-8 pr-8">
                                <v-text-field
                                    disabled
                                    outlined
                                    label="Estatus"
                                    v-model="estatusPeriodo"
                                    class="pa-0 ma-0"
                                >
                                </v-text-field>
                            </v-col>
                        </v-card>
                    </v-col>
                </template>
                <v-col cols="12" lg="12" class="pa-0 ma-0 pb-9" :class="!temporal?'mt-9':'mt-0'">
                    <v-row class="mb-0">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                             <div class="pt-2" style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                <hr class="vr"/>
                                <p class="tblName">Complementos de nómina</p>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                            <div class="float-right pa-0 mb-0 d-flex" >
                                <v-tooltip bottom v-if="temporal">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            fab
                                            color="#1E2245"
                                            right
                                            class="elevation-0 mr-2"
                                            @click="openModal('add')"
                                            width="38"
                                            height="38"
                                        >
                                            <v-icon color="#ffffff" size="20">add</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar</span>
                                </v-tooltip>
                                <v-menu buttom rounded offset-y content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            rounded
                                            color="#58C5D8"
                                            dark
                                            class="btn-agrupar-por justify-space-between elevation-0 mr-2 pr-2"
                                        >
                                            Acciones
                                            <v-icon>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list :class="!temporal?'py-0':'py-0'">
                                        <p class="tituloAcciones pl-8 pt-8 mb-1">Reporte</p>
                                        <v-list-item
                                            class="py-0 px-8"
                                            @click="exportar('pdf')"
                                        >   
                                            <v-list-item-title>Descargar PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            class="py-0 px-8"
                                            @click="exportar('xlsx')"
                                        >   
                                            <v-list-item-title>Descargar documento excel</v-list-item-title>
                                        </v-list-item>
                                    </v-list>

                                    <template>
                                        <v-divider class="mx-4"></v-divider>
                                        
                                        <v-list class="py-0 pb-3">
                                            <p class="tituloAcciones pl-8 pt-5 mb-1">Layout</p>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="layoutComplementos()"
                                            >   
                                                <v-list-item-title>Descargar layout de complementos</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="abrirModalLayoutEmpleados()"
                                            >   
                                                <v-list-item-title>Subir complementos</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        
                                        <template v-if="showODESSA">
                                            <v-divider class="mx-4"></v-divider>
                                            <p class="tituloAcciones pl-8 pt-5 mb-1">ODESSA</p>
                                            <v-list>
                                                <v-list-item
                                                    class="py-0 px-8"
                                                    v-if="showODESSA"
                                                    @click="abrirModalLayoutEmpleados('ODESSA')"
                                                    >   
                                                    <v-list-item-title>Subir complementos ODESSA</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    class="py-0 px-8"
                                                    v-if="showODESSA"
                                                    @click="generarFondo('ODESSA_AUTOMATICO')"
                                                >   
                                                    <v-list-item-title>Generar Fondo de Ahorro ODESSA</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </template>

                                    </template>

                                    
                                    <v-list class="py-0 pb-3" v-show="modeloTipoGrupo == 'SINORDEN' && btnActualizacionMasiva">
                                            
                                        <v-divider class="mx-4"></v-divider>
                                        <p class="tituloAcciones pl-8 pt-5 mb-1">Otras acciones</p>
                                        <template v-if="!temporal">
                                            <template v-if="btnActualizacionMasiva">
                                                <v-list-item
                                                    class="py-0 px-8"
                                                    @click="modificarComplementoEstatus
                                                            ?actualizacionMasiva('AUTORIZADO')
                                                            :'#'"
                                                    :class="modificarComplementoEstatus
                                                            ?''
                                                            :'cursor-not-allowed'"
                                                >   
                                                    <v-list-item-title>Autorizar seleccionados</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    class="py-0 px-8"
                                                    @click="modificarComplementoEstatus
                                                            ?actualizacionMasiva('RECHAZADO')
                                                            :'#'"
                                                    :class="modificarComplementoEstatus
                                                            ?''
                                                            :'cursor-not-allowed'"
                                                >   
                                                    <v-list-item-title>Rechazar seleccionados</v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </template>    
                                        <template v-if="btnActualizacionMasiva">
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="btnShowEliminar
                                                        ?emiminacionMasiva()
                                                        :'#'"
                                                :class="btnShowEliminar
                                                        ?''
                                                        :'cursor-not-allowed'"
                                            >   
                                                <v-list-item-title>Eliminar seleccionados</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                    
                                </v-menu>
                                <v-menu buttom rounded offset-y content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            rounded
                                            class="btn-agrupar-por justify-space-between pr-2 elevation-0"
                                            color="#1E2245"
                                            dark
                                        >
                                            Agrupar por
                                            <v-icon>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in items"
                                            :key="index"
                                            @click="opcionAgrupar(item)"
                                        >
                                            <!-- <v-list-item-icon>
                                                <v-icon color="#BDBDBD" v-text="item.icon"></v-icon>
                                            </v-list-item-icon> -->
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-menu buttom rounded offset-y v-if="!temporal" content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            rounded
                                            class="btn-agrupar-por justify-space-between elevation-0 mr-2 pr-2"
                                            color="#1E2245"
                                            dark
                                        >
                                            {{estatus_filter_nombre}}
                                            <v-icon>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item 
                                            v-for="(item, index) in estatusItemsFilter"
                                            :key="index"
                                            @click="opcionEstatus(item)"
                                        >   
                                            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <!--<div class="autocomplete">
                                <v-autocomplete
                                    outlined
                                    label="Estatus"
                                    style="max-width:140px;border:0px !important;"
                                    :items="estatusItemsFilter"
                                    solo
                                    color='1E2144'
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="estatus_filter">
                                </v-autocomplete>
                                </div>-->
                            </div>
                        </v-col>
                    </v-row>
                    <simple-table
                        v-if="modeloTipoGrupo == 'EMPLEADOS'"
                        :rows="itemsEmpleados"
                        :columns="headersTableEmpleados"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="(item, index) in data">
                                <td>
                                    <expandable-item :image="urlAvatar + item.foto" :title="item.nombre_completo">
                                        <template slot="content">
                                            <table class="tbl-child">
                                                <thead>
                                                    <th>Incidencia</th>
                                                    <th>Importe</th>
                                                    <th v-if="!temporal">Estatus</th>
                                                    <th v-if="!temporal">Repetir</th>
                                                    <th v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">Tipo</th>
                                                    <th>Opciones</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(incidencia, index2) in item.incidencias" :key="index2">
                                                        <td>{{incidencia.codigo_tipo_incidencia}} - {{ incidencia.nombre }}</td>
                                                        <td>{{ formatoMoneda(incidencia.total) }}</td>
                                                        <td v-if="!temporal">
                                                            <v-chip
                                                                class="mx-0 px-0"
                                                                color="transparent"
                                                                :text-color="colorEstatus(incidencia.estatus)"
                                                                outlined
                                                            >
                                                                <img
                                                                    v-if="incidencia.estatus === 'AUTORIZADO'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/aprovado.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="incidencia.estatus === 'RECHAZADO'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/cancelado.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="incidencia.estatus === 'PENDIENTE'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/pendiente.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="incidencia.estatus === 'REVISION'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/revision.png"
                                                                    alt="Empleado"
                                                                />
                                                                    {{ formatoEstatus(incidencia.estatus)}}
                                                            </v-chip>
                                                        </td>
                                                        <td v-if="!temporal">
                                                            {{incidencia.repetir?'SI':'NO'}}
                                                        </td>
                                                        <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                                            {{incidencia.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                                        </td>
                                                        <td>
                                                            <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                                                <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            icon 
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="elevation-0"
                                                                        >
                                                                            <template  v-for="(item,valor) in attrs" >
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                            </template>
                                                                        </v-btn>
                                                                    </template>

                                                                    <v-list>
                                                                        <v-list-item
                                                                            @click="abrirModalEdit(item, incidencia, 'EMPLEADOS')"
                                                                        >
                                                                            <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                                <v-tooltip bottom v-if="btnShowEliminar">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(incidencia.nombre, incidencia.id)">
                                                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Eliminar</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </expandable-item>

                                    <hr v-if="index < data.length - 1" />
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>

                    <!-- TABLA INCIDENCIAS -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'INCIDENCIAS'"
                        :rows="itemsIncidencias"
                        :columns="headersTableIncidencias"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="(item, index) in data">
                                <td>
                                    <expandable-item :title="item.codigo + ' - ' +  item.nombre" :numero="item.empleados.length">
                                        <template slot="content">
                                            <table class="tbl-child">
                                                <thead>
                                                    <th>Empleado</th>
                                                    <th>Importe</th>
                                                    <th v-if="!temporal">Estatus</th>
                                                    <th v-if="!temporal">Repetir</th>
                                                    <th v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">Tipo</th>
                                                    <th>Opciones</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="empleado in item.empleados" :key="empleado.nombre">
                                                        <td>
                                                            <v-list color="transparent" class="py-0 my-0">
                                                                <v-list-item two-line class="py-0 my-0 px-0">
                                                                    <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                                        <img :src="urlAvatar+empleado.foto" alt="Empleado">
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content class="ml-0 py-0 my-0">
                                                                        <v-list-item-title class="list-item-titulo">{{empleado.nombre_completo }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </td>
                                                        <td>{{ formatoMoneda(empleado.total)}}</td>
                                                        <td v-if="!temporal">
                                                            <v-chip
                                                                class="mx-0 px-0"
                                                                color="transparent"
                                                                :text-color="colorEstatus(empleado.estatus)"
                                                                outlined
                                                            >
                                                                <img
                                                                    v-if="empleado.estatus === 'AUTORIZADO'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/aprovado.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="empleado.estatus === 'RECHAZADO'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/cancelado.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="empleado.estatus === 'PENDIENTE'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/pendiente.png"
                                                                    alt="Empleado"
                                                                />
                                                                <img
                                                                    v-else-if="empleado.estatus === 'REVISION'"
                                                                    style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                    src="/static/icon/revision.png"
                                                                    alt="Empleado"
                                                                />
                                                                    <!-- {{ empleado.estatus}} -->
                                                                    {{ formatoEstatus(empleado.estatus)}}
                                                            </v-chip>
                                                        </td>
                                                        <td v-if="!temporal">
                                                            {{empleado.repetir?'SI':'NO'}}
                                                        </td>
                                                        <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                                            <div>
                                                                {{empleado.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                                                <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            icon 
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="elevation-0"
                                                                        >
                                                                            <template  v-for="(item,valor) in attrs" >
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                            </template>
                                                                        </v-btn>
                                                                    </template>

                                                                    <v-list>
                                                                        <v-list-item
                                                                            @click="abrirModalEdit(item, empleado, 'INCIDENCIAS')"
                                                                        >
                                                                            <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                                <v-tooltip bottom v-if="btnShowEliminar">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre, empleado.id)">
                                                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Eliminar</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </expandable-item>

                                    <hr v-if="index < data.length - 1"/>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>

                    <!-- TABLA GENERAL -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'SINORDEN'"
                        :isSelectable="true"
                        :selected="selected_general"
                        @selectAllEvent="getSelected_general"
                        :rows="itemsIncidenciasSinOrden"
                        :columns="!temporal?headersTableGeneral:(periodoI.tipo_periodo_especial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneralTemporal)"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :loading="loading"
                        :idTabla="'tablaSinOrden'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td style="width:80px !important; padding-right:4px !important;">
                                    <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected_general"></v-checkbox>
                                </td> 
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="!temporal">
                                    <v-chip
                                        class="mx-0 px-0"
                                        color="transparent"
                                        :text-color="colorEstatus(item.estatus)"
                                        outlined
                                    >
                                        <img
                                            v-if="item.estatus === 'AUTORIZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/aprovado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'RECHAZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/cancelado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'PENDIENTE'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/pendiente.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'REVISION'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/revision.png"
                                            alt="Empleado"
                                        />
                                            {{ formatoEstatus(item.estatus)}}
                                    </v-chip>
                                </td>
                                <td v-if="!temporal">
                                    <div>
                                        {{item.repetir?'SI':'NO'}}
                                    </div>
                                </td>
                                <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="abrirModalEdit(item, item, 'SINORDEN')"
                                                    >
                                                        <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom v-if="btnShowEliminar">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    
                    <!-- TABLAS PERCEPCIONES-DEDUCIONES-OTROS_PAGOS -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsDeducciones"
                        :columns="!temporal?headersTableGeneral:(periodoI.tipo_periodo_especial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneralTemporal)"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaDeducciones'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="!temporal">
                                    <v-chip
                                        class="mx-0 px-0"
                                        color="transparent"
                                        :text-color="colorEstatus(item.estatus)"
                                        outlined
                                    >
                                        <img
                                            v-if="item.estatus === 'AUTORIZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/aprovado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'RECHAZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/cancelado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'PENDIENTE'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/pendiente.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'REVISION'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/revision.png"
                                            alt="Empleado"
                                        />
                                            {{ formatoEstatus(item.estatus)}}
                                    </v-chip>
                                </td>
                                <td v-if="!temporal">
                                    <div>
                                        {{item.repetir?'SI':'NO'}}
                                    </div>
                                </td>
                                <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                    >
                                                        <v-list-item-title>Modificar complemento</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom v-if="btnShowEliminar">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Total de deducciones: </span><span class="numberResultadoTotal">{{formatoMoneda(totalDeducciones)}}</span> 
                        </v-card>
                    </v-col>
                    

                    
                    <simple-table
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsPercepciones"
                        :columns="!temporal?headersTableGeneral:(periodoI.tipo_periodo_especial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneralTemporal)"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaPercepciones'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="!temporal">
                                    <v-chip
                                        class="mx-0 px-0"
                                        color="transparent"
                                        :text-color="colorEstatus(item.estatus)"
                                        outlined
                                    >
                                        <img
                                            v-if="item.estatus === 'AUTORIZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/aprovado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'RECHAZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/cancelado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'PENDIENTE'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/pendiente.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'REVISION'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/revision.png"
                                            alt="Empleado"
                                        />
                                            {{ formatoEstatus(item.estatus)}}
                                    </v-chip>
                                </td>
                                <td v-if="!temporal">
                                    <div>
                                        {{item.repetir?'SI':'NO'}}
                                    </div>
                                </td>
                                <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                    >
                                                        <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom v-if="btnShowEliminar">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Total de percepciones: </span><span class="numberResultadoTotal">{{formatoMoneda(totalPercepciones)}}</span> 
                        </v-card>
                    </v-col>

                   
                    <simple-table 
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsOtrosPagos"
                        :columns="!temporal?headersTableGeneral:(periodoI.tipo_periodo_especial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneralTemporal)"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaOtrosPagos'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="!temporal"> 
                                    <v-chip
                                        class="mx-0 px-0"
                                        color="transparent"
                                        :text-color="colorEstatus(item.estatus)"
                                        outlined
                                    >
                                        <img
                                            v-if="item.estatus === 'AUTORIZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/aprovado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'RECHAZADO'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/cancelado.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'PENDIENTE'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/pendiente.png"
                                            alt="Empleado"
                                        />
                                        <img
                                            v-else-if="item.estatus === 'REVISION'"
                                            style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                            src="/static/icon/revision.png"
                                            alt="Empleado"
                                        />
                                            {{ formatoEstatus(item.estatus)}}
                                    </v-chip>
                                </td>
                                <td v-if="!temporal">
                                    <div>
                                        {{item.repetir?'SI':'NO'}}
                                    </div>
                                </td>
                                <td v-if="periodoI.tipo_periodo_especial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <template v-if="periodoIncidencias.estatus != 'AUTORIZADO' && periodoIncidencias.estatus != 'RECHAZADO'">
                                            <v-menu class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                    >
                                                        <v-list-item-title>Modificar complemento</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Otros pagos: </span><span class="numberResultadoTotal">{{formatoMoneda(totalOtrosPagos)}}</span> 
                        </v-card>
                    </v-col>

                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="400px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo pb-4">
                                <div class="headerModal">
                                    <!-- <img :src="imagen" class="imgModal" /> -->
                                    <h2 class="titleModal">Editar estatus</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-row class="ma-0 pa-0">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Estatus"
                                                    :items="estatusItems"
                                                    item-text="nombre"
                                                    item-value="valor"
                                                    persistent-hint
                                                    v-model="updateEstatus.estatus"
                                                    class="pa-0 ma-0"
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="updateEstatus.estatus != 'AUTORIZADO'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 ma-0">
                                            <v-textarea
                                                outlined
                                                class="pa-0 ma-0"
                                                label="Observaciones"
                                                v-model="updateEstatus.observaciones"
                                                rows="3"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion pt-0">
                                <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar modal-pull-right" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
            <template class="borde-card">
                <v-dialog v-model="dialogEdit" persistent :max-width="maxWidthModal" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo card_texto pb-5">
                                <div class="headerModal">
                                    <!-- <img :src="imagen" class="imgModal" /> -->
                                    <h2 class="titleModal">Modificar complemento</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                   <v-row class="">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Total"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="totalComplemento" 
                                                v-bind:options="options"
                                                :disabled="!modificarComplementoTotales"
                                            />
                                        </v-col>
                                        <template v-if="showPercepciones">
                                            
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Exento ISR"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="isrExento" 
                                                    v-bind:options="options"
                                                    :disabled="!modificarComplementoTotales"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Gravado ISR"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="isrGravado" 
                                                    v-bind:options="options"
                                                    disabled
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Exento IMSS"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="imssExento" 
                                                    v-bind:options="options"
                                                    :disabled="!modificarComplementoTotales"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Gravado IMSS"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="imssGravado" 
                                                    v-bind:options="options"
                                                    disabled
                                                />
                                            </v-col>
                                        </template>
                                        <template v-if="!temporal">
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="mb-0 py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Estatus"
                                                        :items="estatusItems"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="estatusComplemento"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                        :disabled="!modificarComplementoEstatus"
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="mt-0 pt-0" >
                                                <v-checkbox
                                                    hide-details
                                                    class="pa-0 ma-0"
                                                    v-model="repetirComplemento"
                                                    label="Repetir"
                                                    :disabled="!modificarComplementoTotales"
                                                ></v-checkbox>
                                            </v-col>
                                        </template>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion pt-4">
                                <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar modal-pull-right" @click="guardarComplemento()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
            <template class="borde-card">
                <v-dialog v-model="dialogComplementos" persistent max-width="400px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalComplementos"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">Subir complementos de nómina</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md class="pa-0 mt-4">
                                    <v-row class="ma-0 pa-0">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 ma-0">
                                             <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo"
                                                    :accept="tipo == 'ODESSA'?'.txt':'.xlsx'"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                    class="pa-0"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions  class="d-flex justify-end card_accion pt-0">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" @click="tipo == 'ODESSA'? subirComplementosODESSA():subirComplementos()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>   
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
            <template class="borde-card">
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Errores de complementos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table v-if="tipo != 'ODESSA' && tipo != 'ODESSA_AUTOMATICO'" class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado</th>
                                                    <th class="errorth">Complemento</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresComplementos.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresComplementos">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.erroresComplementos[0].empleado_nombre}}</div>
                                                                <div class="textoTablaError02">{{error.erroresComplementos[0].departamento}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.erroresComplementos[0].complemento}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.erroresComplementos[0].error}}</div> </td>
                                                        </tr>
                                                        <template v-if="error.erroresComplementos.length > 1">
                                                            <tr :key="index2" v-for="(item, index2 ) in error.erroresComplementos.slice(1)">
                                                                <td class="pl-7 errortd"></td>
                                                                <td class="errortd"><div class="textoTablaError01">{{item.complemento}}</div></td>
                                                                <td class="errortd"><div class="textoTablaError03">{{item.error}}</div></td>
                                                            </tr>
                                                        </template>
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                        <table v-else-if="tipo == 'ODESSA'" class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado ID</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresComplementos.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresComplementos">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.empleado}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{'ID empleado incorrecto.'}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                        <table v-else-if="tipo == 'ODESSA_AUTOMATICO'" class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Complemento no generado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresComplementos.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresComplementos">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.empleado}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.complemento}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>




            <template>
                <v-dialog v-model="dialogMotivo" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                    </template>
                    <ValidationObserver ref="formMotivo" v-slot="{ invalid }">
                        <v-card>
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho d-flex justify-center">
                                <div class="header-cancelar">
                                    <img src="/static/icon/alert_warning.png" alt="Imagen cancelar" class="mb-5">
                                    <h2 class="header-cancelar-h2 mb-4">{{ tituloMotivo }}</h2>
                                    <h2 class="header-cancelar-h3 mb-5 mr-1">{{ subtituloMotivo }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pb-0 pt-5">
                                            <ValidationProvider v-slot="{ errors }" name="Motivo" rules="required">
                                                <v-textarea
                                                    outlined
                                                    label="Motivo"
                                                    :rows="2"
                                                    v-model="periodoMotivo"
                                                    :error-messages="errors"
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button 
                                    class="btnCerrar" 
                                    @click="cerrarModalMotivo()" 
                                    :disabled="isSaving"
                                    >Cerrar
                                </button>
                                <v-btn
                                    class="btnCancelar ml-4"
                                    @click="enviarMotivo()" 
                                    :loading="isSaving"
                                    :disabled="invalid" 
                                >
                                    <span class="mx-3">{{estatusMotivo === 'RECHAZADO' ?  'Rechazar periodo':'Enviar a recapturar'}}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Datatable from '@/components/g-datatable/Datatable.vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import Notify from '@/plugins/notify';
import ModalView from '@/views/ModalView.vue';
import apiPeriodoIncidencia from '@/api/periodoIncidencia';
import apiIncidenciasNominas from '@/api/nominas/incidencias';
import queriesPeridosIncidencias from '@/queries/PeriodosIncidencias';
import urlImg from '@/plugins/Urlimagen';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import DynamicInput from '@/components/DynamicInput.vue';
import IncidenciasNominasApi from '@/api/nominas/incidencias';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        ExpandableItem,
        VuetifyDatePicker,
        DynamicInput,
        VuetifyMoneyValidate,
    },
    data() {
        return {
            descripcion_periodo: "",
            datosLogin  : null,
            child_info  : false,
            rol         : null,
            urlAvatar   : null,
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            loading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            brigada           : {
                id                      : null,
                nombre                  : null
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            dialogEdit      : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : '',
            periodoIncidencias  : {
                estatus:'',
                cliente: {
                    odesa:false
                }
            },
            periodoI            : null,
            pCalendar_final : false,
            pCalendar_inicio: false,

            btnUpdate : false,


            /* ****** */
            empleados: [],
            empleado_id: null,
            tipos_incidencias: [],
            tipo_incidencia_id: null,
            campos: [],
            componentKey: 0,
            /* ****** */


            expanded: [],
            singleExpand: false,
            itemsEmpleados : [],
            headersTableEmpleados: [
                { label: "Ver grupo de empleados",    name: "nombre_completo",    filterable: false },
            ],
            headers: [
                {
                    text: 'Empleados',
                    align: 'start',
                    sortable: false,
                    value: 'nombre_completo',
                    class:'cabecera-tabla-izq'
                },
                {   text: '', 
                    value: 'data-table-expand',
                    class:'cabecera-tabla-der'
                },
                
            ],

            expandedIncidencias: [],
            singleExpandIncidencias: false,
            headersTableIncidencias: [
                { label: "Ver lista de complementos de nómina por empleado",    name: "nombre",    filterable: false },
            ],
            headersTablaIncidencias: [
                {
                    text: 'Complementos',
                    align: 'start',
                    sortable: false,
                    value: 'nombre',
                    class:'cabecera-tabla-izq'
                },
                {   text: '', 
                    value: 'data-table-expand',
                    class:'cabecera-tabla-der'
                },
                
            ],
            itemsIncidencias:[],
            selected_general: [],
            headersTableGeneral: [
                { label: "Empleado",    name: "nombre_completo",    filterable: false },
                { label: "Complementos", name: "nombre_incidencia",  filterable: false },
                { label: "Total",       name: "total",              filterable: false },
                { label: "Estatus",     name: "estatus",            filterable: false },
                { label: "Repetir",     name: "repetir",            filterable: false },
                { label: "Opciones",    name: "opciones",           filterable: false },
            ],
            headersTableGeneralTemporal: [
                { label: "Empleado",    name: "nombre_completo",    filterable: false },
                { label: "Complementos", name: "nombre_incidencia",  filterable: false },
                { label: "Total",       name: "total",              filterable: false },
                { label: "Opciones",    name: "opciones",           filterable: false },
            ],
            headersTableGeneralFiniquito: [
                { label: "Empleado",        name: "nombre_completo",    filterable: false },
                { label: "Complementos",    name: "nombre_incidencia",  filterable: false },
                { label: "Total",           name: "total",              filterable: false },
                { label: "Tipo",            name: "tipo",               filterable: false },
                { label: "Opciones",        name: "opciones",           filterable: false },
            ],
            headersTablaSinOrden : [
                {
                    text: 'Empleado',
                    align: 'start',
                    value: 'nombre_completo',
                },
                {
                    text: 'Complementos',
                    align: 'start',
                    value: 'nombre_incidencia',
                },
                {
                    text: 'Total',
                    align: 'start',
                    sortable: false,
                    value: 'total',
                },
                {
                    text: 'Estatus',
                    align: 'start',
                    value: 'estatus',
                },
                {
                    text: 'Opciones',
                    align: 'start',
                    sortable: false,
                    value: 'opciones',
                },
            ],
            headersTablaSinOrdenTemporal : [
                {
                    text: 'Empleado',
                    align: 'start',
                    value: 'nombre_completo',
                },
                {
                    text: 'Complementos',
                    align: 'start',
                    value: 'nombre_incidencia',
                },
                {
                    text: 'Total',
                    align: 'start',
                    sortable: false,
                    value: 'total',
                },
                {
                    text: 'Opciones',
                    align: 'start',
                    sortable: false,
                    value: 'opciones',
                },
            ],
            
            itemsIncidenciasSinOrden:[],
            incidenciasSeleccionadas:[],
            btnActualizacionMasiva: false,
            incidencias_empleados:[],
            itemsTiposGrupo : [
                {id:1, nombre:"Por incidencia",valor:"INCIDENCIAS"}, 
                {id:2, nombre:"Por empleado", valor:"EMPLEADOS"},
                {id:3, nombre:"General", valor:"SINORDEN"},
                {id:4, nombre:"Deducciones-Percepciones-Otros pagos", valor:"PERCEPCIONES-DEDUCIONES-OTROS_PAGOS"}
            ],
            modeloTipoGrupo: 'SINORDEN',
            itemsEstatus : [
                {id:1, nombre:"Todos",valor:"todos"}, 
                {id:2, nombre:"Autorizado",valor:"autorizado"}, 
                {id:3, nombre:"Rechazado", valor:"rechazado"},
                {id:4, nombre:"En proceso", valor:"en_proceso"}
            ],
            estatusItems: [
                {id:1, nombre:"Autorizado",valor:"AUTORIZADO"}, 
                {id:2, nombre:"Rechazado", valor:"RECHAZADO"},
                {id:3, nombre:"Pendiente", valor:"PENDIENTE"},
                {id:3, nombre:"Revisión", valor:"REVISION"}
            ],
            estatusItemsFilter: [
                {id:1, nombre:"Todos",valor:"TODOS"},
                {id:2, nombre:"Autorizado",valor:"AUTORIZADO"}, 
                {id:3, nombre:"Rechazado", valor:"RECHAZADO"},
                {id:4, nombre:"Pendiente", valor:"PENDIENTE"},
                {id:5, nombre:"Revisión", valor:"REVISION"}
            ],
            updateEstatus       : {
                id              : null,
                estatus         : null,
                observaciones   : null
            },setDate(date){
                this.date = date;
            },
            estatus_filter      : "TODOS",
            estatus_filter_nombre: "Todos",
            modeloEstatus : 'todos',
            autoselectMenu01: false,
            autoselectMenu02: false,
            estatusPeriodo  : null,
            showBtnAutorizar: false,
            btnDoc          : false,
            itemsLinks      : [
                {
                    text: 'Periodo de complementos',
                    disabled: false,
                    to: '/periodoIncidencias',
                },
                {
                    text: 'Complementos de nómina',
                    disabled: true,
                    to: '/incidenciasNominas',
                },
            ],
            itemsLinksTemporal   : [
                {
                    text: 'Aguinaldos / Finiquitos',
                    disabled: false,
                    to: '/AguinaldoFiniquito',
                },
                {
                    text: 'Complementos',
                    disabled: true,
                    to: '/incidenciasNominas',
                },
            ],
            itemsLinksTemporalPTU      : [
                {
                    text: 'Nóminas activas de PTU',
                    disabled: false,
                    to: '/PTU',
                },
                {
                    text: 'Complementos',
                    disabled: true,
                    to: '/incidenciasNominas',
                },
            ],
            items: [
                { title: 'General', valor: 'SINORDEN', icon: 'assignment' },
                { title: 'Empleados', valor: 'EMPLEADOS', icon: 'groups' },
                { title: 'Complementos', valor: 'INCIDENCIAS', icon: 'sms_failed' },
                { title: 'Deducciones-Percepciones-Otros pagos', valor: 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS', icon: '$price_change_sharp' }
            ],
            tableClasses: {
                "t-head": {
                "no-radius": false,
                "titulo-first-child": true,
                },
            },
            headersEmpleados: [
                { label: "Empleados", name: "nombre_completo", filterable: false },
                { label: "", name: "", filterable: false },
            ],
            itemsDeduccionesPercepciones:[],
            itemsDeducciones:[],
            itemsPercepciones:[],
            itemsOtrosPagos:[],
            totalDeducciones: 0,
            totalPercepciones:0,
            totalOtrosPagos:0,
            inicioCaptura   : '',
            finCaptura      : '',
            temporal        : false,
            usuarioCapturaId    : null,
            usuarioAutorizaId   : null,
            usuarioCapturaShow  : false,
            usuarioAutorizaShow : false,
            btnShowEliminar     : false,
            idPeriodo           : null,
            complemento         : null,
            loadingLottie       : false,
            fraseLoading        : 'Descargando',
            dialogComplementos  : false,
            archivo             : null,
            dialogErrores       : false,
            erroresComplementos : [],
            totalComplemento    : 0,
            estatusComplemento  : null,
            repetirComplemento  : false,
            isrExento           : 0,
            imssExento          : 0,
            maxWidthModal       : '400px',
            showPercepciones    : false,
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            tipo: null,
            showODESSA: false,

            opcionAgregarComplemento: false,
            opcionEnviarRevision    : false,
            opcionEnviarRecaptura   : false,
            opcionAutorizarPeriodo  : false,
            opcionRechazarPeriodo   : false,
            modificarComplementoTotales: false,
            modificarComplementoEstatus: false,

            dialogMotivo            : false,
            tituloMotivo            : '',
            subtituloMotivo         : '',
            periodoMotivo           : null,
            estatusMotivo           : ''

        }
    },
    computed:{
        itemsLinksComputed(){
            if(!this.temporal){
                return this.itemsLinks
            }
            else{
                if(this.periodoI.tipo_periodo_especial == 'PTU'){
                    return this.itemsLinksTemporalPTU
                }
                else{
                    return this.itemsLinksTemporal
                }
            }
        },
        isrGravado(){
            return this.totalComplemento-this.isrExento
        },
        imssGravado(){
            return this.totalComplemento-this.imssExento
        }
    },
    watch: {
        tipo_incidencia_id: function(value) {
            if(value != null){
                let result  = this.tipos_incidencias.filter(item => item.id == value);
                //console.log("campos",result);
                this.campos = result[0].campos;
                
            }
        },
        modeloTipoGrupo:function(value) {
            this.estatusFilter();
        },
        estatus_filter(){
            this.estatusFilter();
        },
        selected_general:function(value) {
            if(value.length > 0 ){
                this.btnActualizacionMasiva =  true;
            }
            else{
                this.btnActualizacionMasiva =  false;
            }
        }
    },
    methods: {
        forceRerender () {
            this.componentKey += 1;  
        },
        async getTiposIncidencias() {
            let params = {
                cliente_id : this.periodoI.cliente_id
            };

            await IncidenciasNominasApi.getTiposIncidencias(params)
                .then(response => {
                        this.tipos_incidencias = response.data;
                })
                .catch(error => console.error(error));
        },
        toggle01() {
            this.autoselectMenu01 = !this.autoselectMenu01
        },
        toggle02() {
            this.autoselectMenu02 = !this.autoselectMenu02
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

        },
        store() {
            //let valid = this.$refs.dynamicInput.validate();

            //let result = this.$refs.dynamicInput.getData();
        },
        openModal() {
            let resolucion = screen.height;
            let alto = screen.height * 0.70;
            if (resolucion < 850) {
                alto = resolucion * 0.76;
            }

            this.$modal.show(ModalView, {
                objPeriodoIncidencia : !this.temporal?this.periodoIncidencias:this.periodoI,
            }, {
                name: 'incidenciasModal',
                height: alto,
                width: 900,
                scrollable: false,
            }, {
                'before-close': (event) => {
                    this.$apollo.queries.periodo_incidencias.skip = true;
                    this.$apollo.queries.periodo_incidencias.skip = false;
                }
            });
        },
        abrirModal(data = null, subDatos = null){
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png";

            this.updateEstatus.id               = subDatos.id;
            this.updateEstatus.estatus          = subDatos.estatus; 
            this.updateEstatus.observaciones    = subDatos.observaciones;
            
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        abrirModalEdit(data = null, data2 = null, tipoTabla = null){
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png";

            if(tipoTabla == 'SINORDEN'){
                this.complemento = data;
                this.totalComplemento =  data.total;
                this.estatusComplemento =  data.estatus;
                this.repetirComplemento =  data.repetir;

                if(data.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data.excento_isr;
                    this.imssExento = data.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'EMPLEADOS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;

                if(data2.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'INCIDENCIAS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;

                if(data.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;
                if(data2.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            
            this.isLoading  = false;
            this.dialogEdit = true;
        },
        cerrarModal() {
            this.dialog     = false;
            this.dialogEdit = false;
            this.isSaving   = false;
            this.dialogComplementos = false;
            this.resetValues();
        },
        async guardar(){
            await apiIncidenciasNominas.updateEstatusIncidencia(this.updateEstatus).then(response => {
                Notify.Success("Operación exitosa", "El cambio de estatus se realizo satisfactoriamente.");
                this.$apollo.queries.periodo_incidencias.skip = true;
                this.$apollo.queries.periodo_incidencias.skip = false;
                this.cerrarModal();
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                }
                
            });
        },
        async guardarComplemento(){
            let parametros = {};
            this.isSaving = true
            parametros.id       = this.complemento.id;
            parametros.importe  = this.totalComplemento;
            parametros.estatus  = this.estatusComplemento;
            parametros.repetir  = this.repetirComplemento;

            if(this.showPercepciones){
                if(this.isrGravado<0){
                    Notify.ErrorToast("El valor de ISR Gravado debe ser mayor o igual a cero");
                    this.isSaving = false
                    return
                }
                if(this.imssGravado<0){
                    Notify.ErrorToast("El valor de IMSS Gravado debe ser mayor o igual a cero");
                    this.isSaving = false
                    return
                }

                parametros.gravado_isr = this.isrGravado
                parametros.exento_isr = this.isrExento
                parametros.gravado_imss = this.imssGravado
                parametros.exento_imss = this.imssExento
            }

            await apiPeriodoIncidencia.updateImporte(parametros).then(response => {
                Notify.Success("Operación exitosa", "El total del complemento se actualizo satisfactoriamente.");
                this.cerrarModal();
                this.isSaving = false
                this.$apollo.queries.periodo_incidencias.skip = true;
                this.$apollo.queries.periodo_incidencias.skip = false;
            })
            .catch(err => {
                this.isSaving = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        resetValues() {
            this.updateEstatus  = {
                id              : null,
                estatus         : null,
                observaciones   : null
            },
            this.isrExento      = 0;
            this.imssExento     = 0;
            this.archivo = null;
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.form){
                    this.$refs.formMotivo.reset();
                }
            });
        },
        accionesIncidencias(accion = null){
            let errorUpdate = this.periodoIncidencias.estatus;
            let parametros = {
                id          : this.periodoIncidencias.id,
                estatus     : accion,
                rol_name    : this.rol,
                url         : window.location.origin
            }
            switch (accion) {
                case 'REVISION':
                    Notify.Alert(
                        "Revisar periodo",
                        "¿Estás seguro que deseas enviar a revisión el periodo de complementos " + this.periodoIncidencias.periodo + "?",
                        "Revisar",
                        () => {

                            apiPeriodoIncidencia.updateEstatus(parametros).then(response => {
                                Notify.Success("Operación exitosa", "El periodo de complementos cambio a estado revisión");
                                this.$apollo.queries.periodo_incidencias.skip = true;
                                this.$apollo.queries.periodo_incidencias.skip = false;
                            })
                            .catch(err => {
                                this.periodoIncidencias.estatus = errorUpdate;
                                if(typeof err.response.data.error === 'string'){
                                    Notify.ErrorToast(err.response.data.error);
                                }else{
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda+= `* ` + error[i] + `\n`;
                                    }

                                    Notify.ErrorToast(leyenda);
                                }
                            });
                        }
                    )
                    break;
                case 'RECHAZADO':

                    this.dialogMotivo = true;
                    this.tituloMotivo = "Rechazar periodo"
                    this.subtituloMotivo = "¿Estás seguro que deseas rechazar el periodo de complementos " + this.periodoIncidencias.periodo + "?"
                    this.estatusMotivo = 'RECHAZADO'

                    break;
                case 'RECAPTURA':

                    this.dialogMotivo = true;
                    this.tituloMotivo = "Recapturar periodo"
                    this.subtituloMotivo = "Agrega el motivo por el que se envía a recapturar este periodo de incidencias " + this.periodoIncidencias.periodo
                    this.estatusMotivo = 'RECAPTURA'
                    return
                case 'AUTORIZADO':
                    Notify.Alert(
                        "Autorizar periodo",
                        "¿Estás seguro que deseas autorizar el periodo de complementos" + this.periodoIncidencias.periodo + "?",
                        "Autorizar",
                        () => {


                            apiPeriodoIncidencia.updateEstatus(parametros).then(response => {
                                Notify.Success("Operación exitosa", "El periodo de complementos se autorizo satisfactoriamente.");
                                this.$apollo.queries.periodo_incidencias.skip = true;
                                this.$apollo.queries.periodo_incidencias.skip = false;
                            })
                            .catch(err => {
                                console.log(err)
                                this.periodoIncidencias.estatus = errorUpdate;
                                if(typeof err.response.data.error === 'string'){
                                    Notify.ErrorToast(err.response.data.error);
                                }else{
                                    if(typeof err.response.data.error === 'string'){
                                        Notify.ErrorToast(err.response.data.error);
                                    }else{
                                        let error = Object.values(err.response.data.error);
                                        let leyenda = ``;

                                        for (var i = 0; i < error.length; i++) {
                                            leyenda+= `* ` + error[i] + `\n`;
                                        }

                                        Notify.ErrorToast(leyenda);
                                    }
                                }
                            });
                        }
                    )
                    break;
                default:
                    break;
            }
            
            
        },
        async estatusFilter(){

            if(this.temporal){
                await this.getIncidenciasTemporales(this.modeloTipoGrupo, this.periodoI);
            }else{
                await this.getIncidencias(this.modeloTipoGrupo, this.periodo_incidencias);
            }
            
            if(this.estatus_filter !== "TODOS"){

                if(this.itemsIncidenciasSinOrden.length > 0){
                    //console.log("IncSO",this.itemsIncidenciasSinOrden);
                    let newItems = [];
                    for(let item of this.itemsIncidenciasSinOrden){
                        if(item.estatus == this.estatus_filter){
                            newItems.push(item);
                        }
                    }
                    this.itemsIncidenciasSinOrden = newItems;   
                }

                if(this.itemsEmpleados.length > 0){
                    //console.log("Emp",this.itemsEmpleados);
                    let newEmpleados = [];
                    for(let empleado of this.itemsEmpleados){
                        let newItems = [];
                        for(let item of empleado.incidencias){
                            if(item.estatus == this.estatus_filter){
                                newItems.push(item);
                            }
                        }
                        if(newItems.length > 0){
                            empleado.incidencias = newItems;
                            newEmpleados.push(empleado);
                        }
                    }
                    this.itemsEmpleados = newEmpleados;
                }

                if(this.itemsIncidencias.length > 0){
                    //console.log("Inc",this.itemsIncidencias);
                    let newInicidencias = [];
                    for(let incidencia of this.itemsIncidencias){
                        let newItems = [];
                        for(let item of incidencia.empleados){
                            if(item.estatus == this.estatus_filter){
                                newItems.push(item);
                            }
                        }
                        if(newItems.length > 0){
                            incidencia.empleados = newItems;
                            newInicidencias.push(incidencia);
                        }
                    }
                    this.itemsIncidencias = newInicidencias;
                }

                if(this.itemsDeducciones.length > 0){
                    //console.log("Ded",this.itemsDeducciones);
                    let newItems = [];
                    for(let item of this.itemsDeducciones){
                        if(item.estatus == this.estatus_filter){
                            newItems.push(item);
                        }
                    }
                    this.itemsDeducciones = newItems;   
                }

                if(this.itemsPercepciones.length > 0){
                    //console.log("Per",this.itemsPercepciones);
                    let newItems = [];
                    for(let item of this.itemsPercepciones){
                        if(item.estatus == this.estatus_filter){
                            newItems.push(item);
                        }
                    }
                    this.itemsPercepciones = newItems;   
                }

                if(this.itemsOtrosPagos.length > 0){
                    //console.log("OP",this.itemsOtrosPagos);
                    let newItems = [];
                    for(let item of this.itemsOtrosPagos){
                        if(item.estatus == this.estatus_filter){
                            newItems.push(item);
                        }
                    }
                    this.itemsOtrosPagos = newItems;   
                }
            }
                        
        },
        async getIncidenciasTemporales(orden = null, periodo){
            let ordenTipo;
            if(orden == "PERCEPCIONES-DEDUCIONES-OTROS_PAGOS"){
                ordenTipo = "SINORDEN";
            }
            else{
                ordenTipo = orden;
            }
           
            let param = {
                periodo_id : periodo.id, 
                tipo_nomina_id : periodo.tipo_nomina_id,
                orden : ordenTipo,
                usuario_id: this.datosLogin.id
            }
            await apiIncidenciasNominas.incidenciasTemporales(param).then(response => {
                let contadorShowBtn = 0;
                let numIncidencias = 0;

                if(this.temporal){
                    this.periodoI.periodo_incidencia_id = response.periodo_incidencia;
                }
                if(orden === 'INCIDENCIAS'){
                    this.itemsIncidencias = response.data;
                    this.itemsIncidencias.forEach(element => {
                        element.empleados.forEach(el => {
                            numIncidencias++;
                            if(el.estatus == 'AUTORIZADO' || el.estatus == 'RECHAZADO'){
                                contadorShowBtn++;
                            }
                        });
                    });
                }
                else if(orden === 'EMPLEADOS'){
                    this.itemsEmpleados = response.data;
                    this.itemsEmpleados.forEach(element => {
                        numIncidencias++;
                        element.incidencias.forEach(el => {
                            if(el.estatus == 'AUTORIZADO' || el.estatus == 'RECHAZADO'){
                                contadorShowBtn++;
                            }
                        });
                    });
                }
                else if(orden === 'SINORDEN'){
                    this.itemsIncidenciasSinOrden =  response.data;
                    this.itemsIncidenciasSinOrden.forEach(element => {
                        numIncidencias++;
                        if(element.estatus == 'AUTORIZADO' || element.estatus == 'RECHAZADO'){
                            contadorShowBtn++;
                        }
                    });
                }
                 else if(orden === 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'){
                    this.itemsDeduccionesPercepciones =  response.data;
                    this.itemsDeducciones = [];
                    this.itemsPercepciones = [];
                    this.itemsOtrosPagos = [];
                    this.totalDeducciones = 0;
                    this.totalPercepciones = 0;
                    this.totalOtrosPagos = 0;
                    this.itemsDeduccionesPercepciones.forEach(element => {
                        
                        switch (element.codigo_tipo_incidencia.charAt(0)) {
                            case 'D':
                                this.itemsDeducciones.push(element);
                                this.totalDeducciones +=  parseFloat(element.total);
                                break;
                            case 'P':
                                this.itemsPercepciones.push(element);
                                this.totalPercepciones += parseFloat(element.total);
                                break;
                            case 'O':
                                this.itemsOtrosPagos.push(element);
                                this.totalOtrosPagos += parseFloat(element.total);
                                break;
                            default:
                                break;
                        }
                        numIncidencias++;
                        if(element.estatus == 'AUTORIZADO' || element.estatus == 'RECHAZADO'){
                            contadorShowBtn++;
                        }
                    });
                }

                if(numIncidencias == contadorShowBtn){
                    this.showBtnAutorizar = true;
                }
                else{
                    this.showBtnAutorizar = false;
                }
                
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async getIncidencias(orden = null, periodo){
            let ordenTipo;
            if(orden == "PERCEPCIONES-DEDUCIONES-OTROS_PAGOS"){
                ordenTipo = "SINORDEN";
            }
            else{
                ordenTipo = orden;
            }
            
            let param = {
                periodo_incidencia_id : periodo.id, 
                orden : ordenTipo,
                usuario_id: this.datosLogin.id
            }
            await apiIncidenciasNominas.incidencias(param).then(response => {
                let contadorShowBtn = 0;
                let numIncidencias = 0;
                if(orden === 'INCIDENCIAS'){
                    this.itemsIncidencias = response.data;
                    this.itemsIncidencias.forEach(element => {
                        element.empleados.forEach(el => {
                            numIncidencias++;
                            if(el.estatus == 'AUTORIZADO' || el.estatus == 'RECHAZADO'){
                                contadorShowBtn++;
                            }
                        });
                    });
                }
                else if(orden === 'EMPLEADOS'){
                    this.itemsEmpleados = response.data;
                    this.itemsEmpleados.forEach(element => {
                        numIncidencias++;
                        element.incidencias.forEach(el => {
                            if(el.estatus == 'AUTORIZADO' || el.estatus == 'RECHAZADO'){
                                contadorShowBtn++;
                            }
                        });
                    });
                }
                else if(orden === 'SINORDEN'){
                    this.itemsIncidenciasSinOrden =  response.data;
                    this.itemsIncidenciasSinOrden.forEach(element => {
                        numIncidencias++;
                        if(element.estatus == 'AUTORIZADO' || element.estatus == 'RECHAZADO'){
                            contadorShowBtn++;
                        }
                    });
                }
                 else if(orden === 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'){
                    this.itemsDeduccionesPercepciones =  response.data;
                    //console.log(response.data);
                    this.itemsDeducciones = [];
                    this.itemsPercepciones = [];
                    this.itemsOtrosPagos = [];
                    this.totalDeducciones = 0;
                    this.totalPercepciones = 0;
                    this.totalOtrosPagos = 0;
                    this.itemsDeduccionesPercepciones.forEach(element => {
                        
                        switch (element.codigo_tipo_incidencia.charAt(0)) {
                            case 'D':
                                this.itemsDeducciones.push(element);
                                this.totalDeducciones +=  parseFloat(element.total);
                                break;
                            case 'P':
                                this.itemsPercepciones.push(element);
                                this.totalPercepciones += parseFloat(element.total);
                                break;
                            case 'O':
                                this.itemsOtrosPagos.push(element);
                                this.totalOtrosPagos += parseFloat(element.total);
                                break;
                            default:
                                break;
                        }
                        numIncidencias++;
                        if(element.estatus == 'AUTORIZADO' || element.estatus == 'RECHAZADO'){
                            contadorShowBtn++;
                        }
                    });
                }

                if(numIncidencias == contadorShowBtn){
                    this.showBtnAutorizar = true;
                }
                else{
                    this.showBtnAutorizar = false;
                }
                
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        eliminarIncidencia(nombre, incidencia_id){
            Notify.Alert(
                "Eliminar incidencia",
                "¿Estás seguro que deseas eliminar la incidencia " + nombre + "?",
                "Eliminar",
                () => {
                    apiIncidenciasNominas.delete(incidencia_id).then(response => {
                        Notify.Success("Operación exitosa", "La incidencia se eliminó satisfactoriamente.");
                        this.$apollo.queries.periodo_incidencias.skip = true;
                        this.$apollo.queries.periodo_incidencias.skip = false;
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        formatoEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return 'Revisión';
                    break;
                case 'RECHAZADO':
                    return 'Rechazado';
                    break;
                case 'AUTORIZADO':
                    return 'Autorizado';
                    break;
                case 'CAPTURA':
                    return 'Captura';
                    break;
                case 'RECAPTURA':
                    return 'Recaptura';
                    break;
                case 'PENDIENTE':
                    return 'Pendiente';
                    break;
                default:
                    return '';
                    break;
            }
        },
        colorEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return '#FCAA7C';
                    break;
                case 'RECHAZADO':
                    return '#F86868';
                    break;
                case 'AUTORIZADO':
                    return '#86EF83';
                    break;
                case 'CAPTURA':
                    return '#FCAA7C';
                    break;
                case 'PENDIENTE':
                    return '#FED543';
                    break;
                default:
                    return '';
                    break;
            }
        },
        actualizacionMasiva(accion = null){
            let formatIncidencias = [];
            this.selected_general.forEach(element => {
                formatIncidencias.push(element);
            });
            let parametros = { estatus: accion, incidencias : formatIncidencias}
            apiIncidenciasNominas.updateEstatusMasivo(parametros).then(response => {
                if(response.status == 200){
                    Notify.Success("Operación exitosa", "Incidencias seleccionadas actualizadas satisfactoriamente.");
                }
                else if(response.status == 203){
                    Notify.Success("Operación exitosa", "Se ha actualizado los complementos que no pertenecen a ODESA.");
                }
                this.$apollo.queries.periodo_incidencias.skip = true;
                this.$apollo.queries.periodo_incidencias.skip = false;
                this.$refs.tablageneral.unselectAll();
                this.selected_general = [];
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }      
            });
        },
        emiminacionMasiva(){
            let formatIncidencias = [];
            this.selected_general.forEach(element => {
                formatIncidencias.push(element);
            });
            let parametros = { periodo_incidencia:this.idPeriodo, incidencias : formatIncidencias}
            console.log(parametros)

            Notify.Alert(
                "Eliminar incidencia(s)",
                "¿Estás seguro que deseas eliminar la(s) incidencia(s) seleccionada(s)?",
                "Eliminar",
                () => {
                    apiIncidenciasNominas.emiminacionMasiva(parametros).then(response => {
                        Notify.Success("Operación exitosa", "Incidencias seleccionadas actualizadas satisfactoriamente.");
                        this.$apollo.queries.periodo_incidencias.skip = true;
                        this.$apollo.queries.periodo_incidencias.skip = false;
                        this.$refs.tablageneral.unselectAll();
                        this.selected_general = [];
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }      
                    });
                }
            )
        },
        opcionAgrupar(opcion){
            this.modeloTipoGrupo= opcion.valor;
        },
        opcionEstatus(opcion){
            this.estatus_filter = opcion.valor;
            this.estatus_filter_nombre = opcion.nombre;
        },
        async exportar(tipo_doc = null) {
            let sefl = this;
            let url = "incidencias_nomina/reporte";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre = this.descripcion_periodo + '.pdf';
    
            if(tipo_doc == 'xlsx')   
                nombre = this.descripcion_periodo + ".xlsx";
            
            let parametros = { periodo_incidencia_id : this.periodoIncidencias.id , tipo : tipo_doc, orden:this.modeloTipoGrupo, usuario_id:this.datosLogin.id}
            const object1 = parametros;
            try {
                await Vue.axios({
                method: "GET",
                responseType: "blob",
                url: url,
                params: object1,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                });
            } catch (err) {
                var message = null;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                }
            }
        },
        getSelected_general(isSelected, items) {
            this.selected_general = items;
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        layoutComplementos(){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");
            let parametros = {
                cliente_id : this.periodoI.cliente_id,
                periodo_incidencia_id : this.idPeriodo
            }

            let nombre;
            nombre = "Layout_complementos_de_nómina.xlsx";

            let urlCalculo = "incidencias_nomina/exportar-layout";

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error) =>{
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    };
                });
            } 
            catch (err) {
                this.loadingLottie = false;
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                
            }
        },
        abrirModalLayoutEmpleados(tipo = null){
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png";
            this.isLoading  = false;
            this.dialogComplementos = true;
            this.tipo = tipo;
        },
        subirComplementos(){
            let self = this;
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                self.loadingLottie = true;
                self.fraseLoading = "Subiendo archivo";
                
                let formData = new FormData();
                formData.append("archivo", this.archivo);
                formData.append("cliente_id", this.periodoI.cliente_id);
                formData.append("usuario_id", this.datosLogin.id);

                if(this.temporal){
                    formData.append("periodo_incidencia_id", this.periodoI.periodo_incidencia_id);
                }
                else{
                    formData.append("periodo_incidencia_id", this.periodoI.id);
                }

                apiIncidenciasNominas.importarLayout(formData).then((response) => {
                    if (response.status == 200) {
                        Notify.Success("Operación exitosa", "La carga de complementos está lista.");
                    }
                    else if(response.status == 202){
                        let formatErrores = response.data;
                        if(formatErrores.length != 0){
                            let nuevoObjeto = {}
                            formatErrores.forEach( x => {
                                if( !nuevoObjeto.hasOwnProperty(x.empleado_id)){
                                    nuevoObjeto[x.empleado_id] = {
                                        erroresComplementos: []
                                    }
                                }
                                    nuevoObjeto[x.empleado_id].erroresComplementos.push({
                                        empleado_nombre: x.empleado_nombre,
                                        departamento: x.departamento,
                                        complemento: x.complemento,
                                        error:x.error
                                    })
                                
                            })
                            self.erroresComplementos =nuevoObjeto;
                        }
                        self.dialogErrores = true;
                    }
                    else{

                    }
                    self.cerrarModal();
                    self.$apollo.queries.periodo_incidencias.refetch();
                    self.loadingLottie = false;
                })
                .catch (err => {
                    self.isSaving = false;
                    self.loadingLottie = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                })
            });
        },
        subirComplementosODESSA(){
            let self = this;
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                self.loadingLottie = true;
                self.fraseLoading = "Subiendo archivo";
                
                let formData = new FormData();
                formData.append("archivo", this.archivo);
                formData.append("cliente_id", this.periodoI.cliente_id);

                if(this.temporal){
                    formData.append("periodo_incidencia_id", this.periodoI.periodo_incidencia_id);
                }
                else{
                    formData.append("periodo_incidencia_id", this.periodoI.id);
                }
                
                apiIncidenciasNominas.importarLayoutODESSA(formData).then((response) => {
                    if (response.status == 200) {
                        Notify.Success("Operación exitosa", "La carga de complementos se realizo correctamente.");
                    }
                    else if(response.status == 202){
                        self.erroresComplementos = response.data.errores;
                        self.dialogErrores = true;
                    }
                    else{

                    }
                    self.cerrarModal();
                    self.$apollo.queries.periodo_incidencias.refetch();
                    self.loadingLottie = false;
                })
                .catch (err => {
                    self.isSaving = false;
                    self.loadingLottie = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                })
            });
        },
        open(){
            document.getElementById("fileDocument").click();
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresComplementos = [];
        },
        generarFondo(tipo = null){
            this.tipo = tipo;
            self = this;
            Notify.Alert(
                "Generar Fondo de Ahorro ODESSA",
                "¿Estás seguro que deseas generar el fondo de ahorro?",
                "Generar",
                async () => {
                    let parametros = {
                        cliente_id : this.periodoI.cliente_id,
                        periodo_incidencia_id: this.idPeriodo
                    }
                    await apiIncidenciasNominas.generarFondoODESSA(parametros).then(response => {
                        if(response.status == 200){
                            Notify.Success("Operación exitosa", "El proceso se realizo satisfactoriamente.");
                        }
                        else if(response.status == 202){
                            self.erroresComplementos = response.data.errores;
                            self.dialogErrores = true;
                        }
                        else {

                        }
                        this.$apollo.queries.periodo_incidencias.skip = true;
                        this.$apollo.queries.periodo_incidencias.skip = false;
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                        }
                        
                    });
                }
            )
        },

        cerrarModalMotivo() {
            this.dialogMotivo = false
            this.tituloMotivo = ''
            this.subtituloMotivo = ''
            this.periodoMotivo =null
            this.isSaving = false
            this.estatusMotivo = ''



            this.cerrarModal();
        },
        enviarMotivo(){
            this.isSaving = true


            let parametros = {
                id          : this.periodoIncidencias.id,
                estatus     : this.estatusMotivo,
                rol_name    : this.rol,
                url         : window.location.origin,
                motivo      : this.periodoMotivo
            }

            let mensaje = this.estatusMotivo ==='RECHAZADO' ? "El periodo de complementos se rechazo": "El periodo de complementos se envió a recaptura"

            apiPeriodoIncidencia.updateEstatus(parametros).then(response => {
                Notify.Success("Operación exitosa", mensaje);
                this.$apollo.queries.periodo_incidencias.skip = true;
                this.$apollo.queries.periodo_incidencias.skip = false;
                this.isSaving = false
                this.cerrarModalMotivo()
            })
            .catch(err => {
                this.isSaving = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                }
            });
        },
    },
    beforeCreate(){
        if(!this.$session.get('periodoIncidencias')){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.urlAvatar          = urlImg.getURL();
        this.periodoI = this.$session.get('periodoIncidencias');
        //console.log(this.periodoI)
        this.temporal = this.$session.get('temporal');
        this.usuarioCapturaId   = this.periodoI.usuario_captura_id;
        this.usuarioAutorizaId  = this.periodoI.usuario_autoriza_id;

        this.usuarioCapturaShow     = (this.usuarioCapturaId === this.datosLogin.id) ? true : false;
        this.usuarioAutorizaShow    = (this.usuarioAutorizaId === this.datosLogin.id) ? true : false;
        if(this.temporal){
            this.btnShowEliminar = true;
            this.idPeriodo = this.$session.get('periodoIncidencias').periodo_incidencia;
        }
        else{
            this.idPeriodo = this.periodoI.id;
        }



        /**condicion para que al regresar a la ruta de PTU se posicione en el tab(seccion) de 'Nóminas de PTU activas'*/
        if(sessionStorage.getItem('rutaFrom') == 'PTU'){
            sessionStorage.setItem("ptuActivas", 'ptuActivas');
        }
        else{
            sessionStorage.removeItem("ptuActivas")
        }
       
    },
    mounted(){
        //$(".v-menu__content").css({'color':'red',  'border-radius':'15px' });
        this.$apollo.queries.periodo_incidencias.skip = true;
        this.$apollo.queries.periodo_incidencias.skip = false;
        this.getTiposIncidencias();
    },
    updated() {     
        
        if(this.modeloTipoGrupo == 'SINORDEN'){
            let tablaSinOrden = document.querySelector('#tablaSinOrden');
            if(tablaSinOrden){
                document.querySelector('#tablaSinOrden thead tr th:nth-child(1)').style.paddingRight   = "4px";
                document.querySelector('#tablaSinOrden thead tr th:nth-child(1)').style.width   = "80px";
                document.querySelector('#tablaSinOrden thead tr th:nth-child(2)').style.paddingRight   = "0px";
            }
        }
        let tablaDeducciones = document.querySelector('#tablaDeducciones');
        if(tablaDeducciones){
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingBottom   = "20px";
            
        }
        let tablaPercepciones = document.querySelector('#tablaPercepciones');
        if(tablaPercepciones){
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingBottom   = "20px";
        }
        let tablaOtrosPagos = document.querySelector('#tablaOtrosPagos');
        if(tablaOtrosPagos){
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingBottom   = "20px";
        }
        
    },
    apollo: {
        periodo_incidencias: {
            query       : queriesPeridosIncidencias.getPeriodoIncidenciaQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.loading = true;
                return {
                    id  :  this.idPeriodo
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                /* if(this.periodo_incidencias == null){
                    this.loading = false;
                    return;
                } */
            
                this.periodoIncidencias = this.periodo_incidencias;
                this.descripcion_periodo = this.periodo_incidencias.periodo +" - "+ this.periodo_incidencias.tipoNomina.descripcion;
                //console.log(this.periodoIncidencias)
                this.estatusPeriodo = !this.temporal?this.formatoEstatus(this.periodo_incidencias.estatus):null;
                this.inicioCaptura  = !this.temporal?this.dateFormat(this.periodo_incidencias.inicio_captura):null;
                this.finCaptura     = !this.temporal?this.dateFormat(this.periodo_incidencias.final_captura):null;
                if(this.temporal){
                    this.getIncidenciasTemporales(this.modeloTipoGrupo, this.periodoI);
                    this.periodoIncidencias.estatus = 'CAPTURA';
                    this.btnShowEliminar = true;
                    this.modificarComplementoTotales = true
                    this.modificarComplementoEstatus = false

                }else{
                    this.getIncidencias(this.modeloTipoGrupo, this.periodo_incidencias);

                    this.btnShowEliminar            = false
                    this.opcionAgregarComplemento   = false
                    this.opcionEnviarRevision       = false
                    this.opcionEnviarRecaptura      = false
                    this.opcionAutorizarPeriodo     = false
                    this.opcionRechazarPeriodo      = false

                    this.modificarComplementoTotales = false
                    this.modificarComplementoEstatus = false

                    if(this.usuarioCapturaShow === true && this.usuarioAutorizaShow === true){

                        this.modificarComplementoTotales = true
                        this.modificarComplementoEstatus = true

                        if(this.periodo_incidencias.estatus === 'CAPTURA'){
                            this.btnShowEliminar            = true
                            this.opcionAgregarComplemento   = true
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = true
                            this.opcionRechazarPeriodo      = true
                        }
                        else if(this.periodo_incidencias.estatus === 'RECAPTURA'){
                            this.btnShowEliminar            = true
                            this.opcionAgregarComplemento   = true
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = true
                            this.opcionRechazarPeriodo      = true
                        }
                        else if(this.periodo_incidencias.estatus === 'AUTORIZADO'){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = true
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = true
                        }
                        else if(this.periodo_incidencias.estatus === 'RECHAZADO'){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = true
                            this.opcionAutorizarPeriodo     = true
                            this.opcionRechazarPeriodo      = false
                        }
                    }
                    else if(this.usuarioCapturaShow === true && this.usuarioAutorizaShow === false){
                        this.modificarComplementoTotales = true
                        this.modificarComplementoEstatus = false

                        if(
                            this.periodo_incidencias.estatus === 'CAPTURA' ||
                            this.periodo_incidencias.estatus === 'RECAPTURA'
                        ){
                            this.btnShowEliminar            = true
                            this.opcionAgregarComplemento   = true
                            this.opcionEnviarRevision       = true
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = false
                        }
                        else if (
                            this.periodo_incidencias.estatus === 'AUTORIZADO' ||
                            this.periodo_incidencias.estatus === 'RECHAZADO' || 
                            this.periodo_incidencias.estatus === 'REVISION'
                        ){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = false
                        }
                       
                    }
                    else if(this.usuarioCapturaShow === false && this.usuarioAutorizaShow === true){
                        this.modificarComplementoTotales = false
                        this.modificarComplementoEstatus = true

                        if(this.periodo_incidencias.estatus === 'CAPTURA'){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = true
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = false
                        }
                        else if (this.periodo_incidencias.estatus === 'AUTORIZADO' ){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = true
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = true
                        }
                        else if (this.periodo_incidencias.estatus === 'RECHAZADO' ){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = true
                            this.opcionAutorizarPeriodo     = true
                            this.opcionRechazarPeriodo      = false
                        }
                        else if (this.periodo_incidencias.estatus === 'REVISION'){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = false
                            this.opcionEnviarRecaptura      = true
                            this.opcionAutorizarPeriodo     = true
                            this.opcionRechazarPeriodo      = true

                        }
                        else if(this.periodo_incidencias.estatus === 'RECAPTURA'){
                            this.btnShowEliminar            = false
                            this.opcionAgregarComplemento   = false
                            this.opcionEnviarRevision       = true
                            this.opcionEnviarRecaptura      = false
                            this.opcionAutorizarPeriodo     = false
                            this.opcionRechazarPeriodo      = false
                        }
                    }
                    else if(this.rol == 'root'){
                        this.btnShowEliminar            = true
                        this.opcionAgregarComplemento   = true
                        this.opcionEnviarRevision       = true
                        this.opcionEnviarRecaptura      = true
                        this.opcionAutorizarPeriodo     = true
                        this.opcionRechazarPeriodo      = true

                        this.modificarComplementoTotales = true
                        this.modificarComplementoEstatus = true
                    }

                }


                if(this.periodoIncidencias.cliente.odesa == true){
                    this.showODESSA = true;
                }
                else{
                    this.showODESSA = false;
                }
                
                this.loading = false;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tituloPeriodoIncidencia{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #101C5A;
    }
    .borde-tabla.v-data-table {
        border-radius: 20px !important;
    }
    .texto-tabla{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        color: #828282 !important;
    }
    .list-item-titulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #828282;
    }
    .v-data-table td {
        font-size: 0.75rem; /* 0.75 rem = 12 px */
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
    .btn-agrupar-por {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* padding-right: 10px !important; */
        text-transform: none;
        height: 38px !important;
        min-width: 140px !important;
    }
    .v-menu__content{
        border-radius: 15px;
        margin-top: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

     /**
    * COMPONENT EXPANDABLE ROW
    */
    .tbl-incidencias tr:first-child td {
        padding-top: 15px;
    }
    .tbl-incidencias tr td {
        padding-right: 43px !important;
        padding-left: 43px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .divider {
        border-top: 0.5px solid rgba(87, 87, 92, 0.4);
    }

    .tbl-child {
        box-shadow: none !important;
        width: 100%;
        border-collapse: collapse;
    }
    .tbl-child thead th {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        align-items: center;

        height: 42px;
        background: #101C5A05 !important;
        color: #101C5A;
        padding: 13px 0;
    }
    .tbl-child thead th:first-child,
    .tbl-child tbody td:first-child {
        padding-left: 150px !important;
    }
    .tbl-child tbody td:last-child {
        padding-right: 43px !important;
    }

    .tbl-child tbody td {
        padding-left: 0 !important;
    }


    .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
        color: blue !important;
    }

    .textResultadoTotal{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1E2144;
    }
    .numberResultadoTotal{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1E2144;
    }
    [v-cloak] {
        display: none;
    }
    .border-gray-300{
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
    .tituloAcciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #1E2245;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
        margin-top:15px;
        background-color: #FFFFFF;
    }
    .cursor-not-allowed:hover{
        cursor:not-allowed !important
    }

    #btnModalErrores{
        display: none;
    }


    .btnCancelar {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#D41010 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }

    .header-cancelar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    .header-cancelar-h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 17px;
        color: #D41010;
    }
    .header-cancelar-h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #828282;
    }
</style>
